import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { GlobalService, Result } from '../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  txtPassword = '';
  isSpinning = false;

  constructor(
    private router: Router,
    private message: NzMessageService,
    public g: GlobalService
  ) { }

  ngOnInit() {
  }
  // ################################################
  // LOGIN
  login() {
    this.g.userLogin = { password: this.txtPassword, logged: false };
    this.isSpinning = true;

    // this.g.login()
    //   .subscribe((res: Result) => {
    //     this.isSpinning = false;

    //     if (res.result === true) {
    //       this.message.success('Codice accettato');

    //       this.g.userLogin = res.data;
    //       sessionStorage.setItem('ibadmin', JSON.stringify(this.g.userLogin));

    //       this.router.navigateByUrl('/admin/home');
    //     } else {
    //       this.message.warning(res.message);
    //     }
    //   }, (error: HttpErrorResponse) => {
    //     this.isSpinning = false;
    //     this.message.warning('Si è vericato un errore non previsto:<br>"' + error.statusText + '"');
    //   });
    this.g.login().subscribe((res: Result) => {
      this.isSpinning = false;
      if (res.result === true) {
        this.message.success('Codice accettato');
        this.router.navigateByUrl('/home');
      } else if (res.result === false) {
        this.message.warning(res.message);
      } else {
        this.message.warning('Si è vericato un errore non previsto:<br>"' + res.message + '"');
      }
    });
  }
}
