import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, it_IT } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it';
import { ColorPickerModule } from 'ngx-color-picker';

// ## SERVUCES
import { GlobalService } from './services/global.service';
// ## COMPONENTS
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { CampagneComponent } from './campagne/campagne.component';
import { CategorieComponent } from './categorie/categorie.component';
import { PremiComponent } from './premi/premi.component';
import { AdvComponent } from './adv/adv.component';
registerLocaleData(it);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    CampagneComponent,
    CategorieComponent,
    PremiComponent,
    AdvComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ColorPickerModule
  ],
  providers: [{ provide: NZ_I18N, useValue: it_IT },
    GlobalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
