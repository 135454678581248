import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CampagneComponent } from './campagne/campagne.component';
import { CategorieComponent } from './categorie/categorie.component';
import { PremiComponent } from './premi/premi.component';
import { AdvComponent } from './adv/adv.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home', component: HomeComponent,
    children: [
      { path: 'campagne', component: CampagneComponent, outlet: 'adminContent' },
      { path: 'categorie', component: CategorieComponent, outlet: 'adminContent' },
      { path: 'premi', component: PremiComponent, outlet: 'adminContent' },
      { path: 'adv', component: AdvComponent, outlet: 'adminContent' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
