import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService, UserLogin } from '../services/global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public isCollapsed: boolean;

  constructor(
    private router: Router,
    public g: GlobalService) {
  }

  ngOnInit() {
    this.g.homeTitle = 'Amministrazione iBApp';
  }

  // ################################################
  // LOGOUT
  logOut($element) {
      this.g.logout();
      this.router.navigateByUrl('/login');
  }
}

