import { Component, OnInit } from '@angular/core';
import { Campagna, GlobalService, Result } from '../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NzNotificationService, NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-campagne',
  templateUrl: './campagne.component.html',
  styleUrls: ['./campagne.component.scss']
})
export class CampagneComponent {
  // CONFIG
  dataCfg = {
    tableTitle: 'Tabella Campagne',
    table: 'campagne',
    key: 'id',
    checkDup: 'titolo',

  };

  // COMPONENT VARS
  public newItem: Campagna = { attiva: false } as Campagna;
  public newCodeError: string = 'validating';
  isLoading: boolean = true;

  // TABLE VARS
  dataSet = [];
  public displayData = [...this.dataSet];
  editCache = {};
  sortName = null;
  sortValue = null;

  // ################################################
  // CONSTRUCTOR
  constructor(
    public g: GlobalService,
    private notification: NzNotificationService,
    private message: NzMessageService
  ) {
    // SETUP INTERFACE
    this.g.menuState = 1;
    this.g.homeTitle = this.dataCfg.tableTitle;

    // LOAD DATA
    this.loadData();
  }
  // ################################################
  // LOAD DATA
  loadData() {
    this.isLoading = true;
    const query: string = 'SELECT * from ' + this.dataCfg.table;
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        this.dataSet = res.data;
        this.updateEditCache();
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto', error.statusText, { nzDuration: 0 });
      });
  }
  // ################################################
  // UPDATE EDIT CACHE
  updateEditCache(): void {
    if (this.dataSet != null) {
      this.dataSet.forEach(item => {
        if (!this.editCache[item.id]) {
          this.editCache[item.id] = {
            edit: false,
            data: item
          };
        }
      });
    }
    this.displayData = [...this.dataSet];
  }
  // ################################################
  // START EDIT
  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }
  // ################################################
  // CANCEL EDIT
  cancelEdit(id: string): void {
    this.editCache[id].edit = false;
  }
  // ################################################
  // SAVE NEW
  saveNew(): void {
    const check = this.dataSet.findIndex(item => item[this.dataCfg.checkDup] === this.newItem[this.dataCfg.checkDup]);
    if (check !== -1) {
      this.notification.error('Record già presente',
        'Attenzione: il record inserito è già presente nell\'archivio [' + this.dataCfg.checkDup + '].', { nzDuration: this.g.fastTime });
      this.newCodeError = 'error';
      return;
    }

    // ############################### CUSTOMIZE
    const query: string = 'INSERT INTO ' + this.dataCfg.table +
      ' ( `titolo`, `attiva`) VALUES ("' +
      this.newItem.titolo + '",' +
      this.newItem.attiva + ')';
    // ###############################

    this.isLoading = true;
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        if (res.result === false) {
          this.newItem[this.dataCfg.key] = res.data.insertId;
          this.dataSet.push(this.newItem);

          this.newItem = { attiva: false } as Campagna;
          this.newCodeError = 'validating';

          this.message.success('Nuovo record creato con successo!');
          this.updateEditCache();
        } else {
          this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.',
            res.message, { nzDuration: this.g.normalTime });
          console.log(res);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
      });
  }
  // ##############################################
  // SORT
  sort(sort: { key: string, value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    if (this.sortName) {
      this.displayData = [... this.dataSet.sort((a, b) => (this.sortValue === 'ascend') ?
        (a[this.sortName] > b[this.sortName] ? 1 : -1) : (b[this.sortName] > a[this.sortName] ? 1 : -1))];
    } else {
      this.displayData = [...this.dataSet];
    }
  }
  // ##############################################
  // SAVE EDIT
  saveEdit(id: string): void {
    const data: Campagna = this.editCache[id].data;
    const index = this.dataSet.findIndex(item => item.id === id);

    const check = this.dataSet.findIndex(item => item.titolo === data.titolo);
    if (check !== -1 && check !== index) {
      this.notification.error('Record già presente',
        'Attenzione: il record inserito è già presente nell\'archivio [' + this.dataCfg.checkDup + '].', { nzDuration: this.g.fastTime });
      return;
    }

    // ********************************* CUSTOMIZE
    this.dataSet[index] = this.editCache[id].data;
    this.editCache[id].edit = false;
    const query: string = 'UPDATE ' + this.dataCfg.table +
      ' SET `titolo`="' + data.titolo +
      '", `attiva`="' + data.attiva +
      '" WHERE `id`=' + data[this.dataCfg.key];
    // *********************************

    this.isLoading = true;
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        if (res.result === false) {
          this.message.success('Modifica completata con successo!');
          this.updateEditCache();
        } else {
          this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.', res.message, { nzDuration: 0 });
          console.log(res);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
      });
  }
  // ##############################################
  // DELETE ROW
  deleteRow(id: string): void {
    const index = this.dataSet.findIndex(item => item.id === id);
    const query: string = 'DELETE FROM ' + this.dataCfg.table + ' WHERE `id`= "' + this.dataSet[index].id + '"';
    this.isLoading = true;
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        if (res.result === false) {
          this.dataSet.splice(index, 1);
          this.message.success('Cancellazione completata con successo!');
          this.updateEditCache();
        } else {
          this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.', res.message, { nzDuration: 0 });
          console.log(res);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
      });
  }
  // ##############################################
  // SAVE CHECK
  saveCheck(id: string): void {
    const data: Campagna = this.editCache[id].data;
    const index = this.dataSet.findIndex(item => item.id === id);
    this.dataSet[index] = this.editCache[id].data;
    this.editCache[id].edit = false;

    // ********************************* CUSTOMIZE
    data.attiva = !data.attiva;
    const query: string = 'UPDATE ' + this.dataCfg.table +
      ' SET `attiva`=' + ((data.attiva) ? 'true' : 'false') + ' WHERE `id`=' + data.id;
    // *********************************

    this.isLoading = true;

    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        if (res.result === false) {
          this.message.success('Modifica completata con successo!');
          this.updateEditCache();
        } else {
          this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.', res.message, { nzDuration: 0 });
          console.log(res);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
      });
  }
}
